import './onePlatform.scss';
import PlatformItem from './PlatformItem';

import React from 'react';

import Container from 'components/layout/Container';
import Section from 'components/layout/Section';

import APIImage from 'assets/images/APIImage.svg';
import DataImage from 'assets/images/DataImage.svg';
import ResearchImage from 'assets/images/ResearchImage.svg';

export default function onePlatform() {
    return (
        <Container className="platformColumns paddingYB5" theme="light-grey">
            <Section type="full">
                <div>
                    <h2 className="section-header pricingTitle">Use Cases</h2>
                    <p className="platformHeaderText">Real Car Pricing is used by companies across the automotive and related industries.</p>
                </div>

                <div className="platformProducts paddingY5">
                    <div className="platformAPI">
                        <a href="/vin-decoder/" alt="api">
                            <PlatformItem>
                                <div className="platformImageWrapper wrapperAPI">
                                    <APIImage />
                                </div>
                                <div className="subheaderWrapper">
                                    <h2 className="platformHeader">Insurance Premiums & Valuations</h2>
                                </div>
                                <p className="paddingY2 platformText">Get accurate vehicle valuations to determine insurance premiums or market values for policies.</p>
                            </PlatformItem>
                        </a>
                    </div>

                    <div className="platformAPI">
                        <a href="/car-sales-statistics/" alt="car sales statistics">
                            <PlatformItem>
                                <div className="platformImageWrapper wrapperData">
                                    <DataImage />
                                </div>
                                <div className="subheaderWrapper">
                                    <h2 className="platformHeader">Fleet & Loan Book Valuations</h2>
                                </div>
                                <p className="paddingY2 platformText">Instantly check the value of your entire vehicle fleet.</p>
                            </PlatformItem>
                        </a>
                    </div>

                    <div className="platformAPI">
                        <a href="/car-sales-statistics/" alt="research and reporting">
                            <PlatformItem>
                                <div className="platformImageWrapper wrapperResearch">
                                    <ResearchImage />
                                </div>
                                <div className="subheaderWrapper">
                                    <h2 className="platformHeader">Guaranteed Future Value (GFV)</h2>
                                </div>
                                <p className="paddingY2 platformText">Use RCP as an ingredient for your GFV calculations.</p>
                            </PlatformItem>
                        </a>
                    </div>
                </div>
            </Section>
        </Container>
    );
}
